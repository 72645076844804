import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';
import {Router } from 'react-router-dom';


ReactDOM.render((
    
      <App /> 
    
    ), document.getElementById('root')
  );

